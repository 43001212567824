<template>
  <div
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="拼命加载中..."
    class="sub-user-edit-dialog"
  >
    <el-form
      ref="form"
      :model="subUserModel"
      inline
      size="mini"
      label-width="100px"
      label-suffix=":"
      :rules="subUserFormRules"
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="subUserModel.name" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="subUserModel.phone" />
      </el-form-item>
      <br />
      <el-form-item label="角色" prop="roleIds">
        <el-checkbox-group v-model="subUserModel.roleIds" :min="1">
          <el-checkbox v-for="role in roles" :key="role.id" :label="role.id">{{
            role.roleName
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "sub-user-edit-dialog",
  props: {
    action: {
      type: String,
      require: true,
    },
    id: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      loading: true,
      subUserFormRules: {
        name: [{ required: true, message: "姓名必填", trigger: "blur" }],
        phone: [
          { required: true, message: "手机号必填", trigger: "blur" },
          {
            pattern:
              "^(13[0-9]|14[5-9]|15[0-3,5-9]|16[2,5,6,7]|17[0-8]|18[0-9]|19[0-3,5-9])\\d{8}$",
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        roleIds: [
          { required: true, message: "必须选则至少一个角色", trigger: "blur" },
        ],
      },
      subUserModel: {
        roleIds: [],
      },
      subUserModelChanged: false,
      roles: [],
    };
  },
  methods: {
    async opened() {
      this.loadRoles();
      this.loadUserInfo();
    },
    async closed() {
      this.loading = true;
      this.reset();
    },
    reset() {
      this.subUserModel = { roleIds: [] };
      this.$refs.form.clearValidate();
    },
    async loadRoles() {
      let data = await this.$http.doApi("fw-cms-role-list", {}, { flag: 1 });
      this.roles = data.rows;
    },
    async loadUserInfo() {
      this.loading = true;
      try {
        if (this.id && this.id > 0) {
          let userInfo = await this.$http.doApi("fw-cms-user-getDetail", {
            id: this.id,
          });
          if (!userInfo.roleIds) {
            userInfo.roleIds = [];
          }
          this.subUserModel = userInfo;
          this.subUserModelChanged = false;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async getFormValues() {
      await this.$refs.form.validate();
      return this.subUserModel;
    },
  },
  watch: {
    subUserModel: {
      deep: true,
      handler() {
        this.subUserModelChanged = true;
      },
    },
  },
};
</script>

<style lang="less">
.sub-user-edit-dialog {
  border-top: 1px dotted #cccccc;
  padding-top: 1rem;
  text-align: center;
}
</style>